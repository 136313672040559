import React from 'react';
import useTranslation from 'next-translate/useTranslation';

import { CurrencyConvertor } from '~/components/utils/formatters';
import { CarouselSlideActionVariant } from '~/domains/cms/PromoCarousel/enums';
import { Routes } from '~/domains/common/constants/routes';

import ActionButton from '../GenericSlide/ActionButton';

type EnterContestActionProps = {
  contestId: string;
  entryFee: number;
  onClick?: () => void;
};

function EnterContestAction({ contestId, entryFee, onClick }: EnterContestActionProps) {
  const { t } = useTranslation('contest');

  return (
    <ActionButton
      variant={CarouselSlideActionVariant.FILLED}
      href={Routes.contestDetail(contestId, { enterContest: true })}
      onClick={onClick}
    >
      {t('lobby.carousel.play', {
        entryFee: CurrencyConvertor(entryFee / 100),
      })}
    </ActionButton>
  );
}

export default EnterContestAction;
