import React from 'react';
import { IMAGE_MIME_TYPES } from '@betterpool/strapi-client/dist/types';

import type { CarouselSlideContest } from '~/domains/cms/PromoCarousel/types';
import { useContest } from '~/domains/contest/hooks/useContest';

import ContestSlide from './ContestSlide';
import backgroundImageExample from './backgroundImageExample.svg';

type ConnectedContestSlideProps = CarouselSlideContest & {
  isMobileVariant?: boolean;
  isCountdownPillEnabled?: boolean;
  slideIndex: number;
  onEnterContestClick?: () => void;
};

function ConnectedContestSlide({
  title,
  contestId,
  backgroundImage,
  rightImageMobile,
  rightImageDesktop,
  isMobileVariant,
  isCountdownPillEnabled,
  slideIndex,
  onEnterContestClick,
}: ConnectedContestSlideProps) {
  const { data, isLoading, isError } = useContest(contestId);
  return (
    <ContestSlide
      contestId={contestId}
      entries={data?.entries}
      entryFee={data?.entry_fee}
      owner={data?.commissioner.username}
      prize={data?.prize_pool}
      title={title ?? data?.name}
      backgroundImage={
        backgroundImage ?? {
          name: 'background',
          mime: IMAGE_MIME_TYPES.SVG,
          url: backgroundImageExample.src,
          width: backgroundImageExample.width,
          height: backgroundImageExample.height,
        }
      }
      rightImageMobile={rightImageMobile}
      rightImageDesktop={rightImageDesktop}
      isLoading={isLoading}
      isError={isError}
      isMobileVariant={isMobileVariant}
      contestStartDate={isCountdownPillEnabled && data?.start_date}
      slideIndex={slideIndex}
      onEnterContestClick={onEnterContestClick}
    />
  );
}

export default ConnectedContestSlide;
