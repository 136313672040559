import React from 'react';

import { CarouselSlideActionVariant } from '~/domains/cms/PromoCarousel/enums';
import { ButtonLink, PrimaryButtonLink } from '~/domains/common/components/Button/Button';

type ActionButtonProps = {
  variant?: CarouselSlideActionVariant;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  href?: string;
  width?: number;
  disabled?: boolean;
};

function ActionButton({
  variant = CarouselSlideActionVariant.DEFAULT,
  children,
  onClick,
  href = '',
  width,
  disabled,
}: ActionButtonProps) {
  switch (variant) {
    case CarouselSlideActionVariant.FILLED: {
      return (
        <PrimaryButtonLink href={href} onClick={onClick} w={width} disabled={disabled}>
          {children}
        </PrimaryButtonLink>
      );
    }
    default: {
      return (
        <ButtonLink href={href} variant={variant} onClick={onClick} w={width} disabled={disabled}>
          {children}
        </ButtonLink>
      );
    }
  }
}

export default ActionButton;
