import React from 'react';
import { IconInfoCircle } from '@tabler/icons-react';

import { CarouselSlideActionIcon } from '~/domains/cms/PromoCarousel/enums';

function ActionIcon({ icon }: { icon: string }) {
  switch (icon) {
    case CarouselSlideActionIcon.INFO_CIRCLE: {
      return <IconInfoCircle />;
    }
    default: {
      return null;
    }
  }
}

export default ActionIcon;
