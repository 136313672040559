import { createStyles } from '@mantine/core';

import { CarouselSlideGenericAlign } from '~/domains/cms/PromoCarousel/enums';

type CreateStyleProps = {
  backgroundImage?: string;
  align?: CarouselSlideGenericAlign;
  isMobile?: boolean;
};

const useStyles = createStyles(
  (
    theme,
    { backgroundImage, align = CarouselSlideGenericAlign.LEFT, isMobile }: CreateStyleProps
  ) => ({
    container: {
      width: '100%',
      height: 350,
      position: 'relative',
      backgroundColor: theme.colors.gray[9],
      color: theme.white,
      padding: '52px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      borderRadius: theme.radius.lg,
      ...(backgroundImage
        ? {
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
          }
        : null),

      [theme.fn.smallerThan('md')]: {
        padding: theme.spacing.lg,
      },
      ...(isMobile
        ? {
            padding: `${theme.spacing.sm}px !important`,
            height: 220,
            borderRadius: theme.radius.md,
          }
        : {}),
    },
    subtitle: {
      margin: 0,
      fontSize: isMobile ? theme.fontSizes.sm : theme.fontSizes.lg,
      lineHeight: 1.2,
      textAlign: align,

      '&.withImage': {
        maxWidth: '70%',
      },
    },
    content: {
      position: 'relative',
      zIndex: 2,
    },
    rightImage: {
      zIndex: 1,
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
      height: '100%',
      objectPosition: 'right',
      objectFit: 'contain',
    },
  })
);

export default useStyles;
