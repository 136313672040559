import styled from '@emotion/styled';

import { CarouselSlideGenericAlign } from '~/domains/cms/PromoCarousel/enums';

type GenericSlideTitleProps = {
  align?: CarouselSlideGenericAlign;
  withImage?: boolean;
  isMobile?: boolean;
};

const GenericSlideTitle = styled.h2<GenericSlideTitleProps>(
  ({ align = CarouselSlideGenericAlign.LEFT, withImage, isMobile }) => `
    font-size: ${isMobile ? '22px' : '40px'};
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
    text-align: ${align};
    ${withImage ? 'max-width: 70%' : ''};
`
);

export default GenericSlideTitle;
