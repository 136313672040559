import React from 'react';
import Image from 'next/image';
import { Group, MantineProvider, Paper, Stack } from '@mantine/core';
import { IMAGE_MIME_TYPES } from '@betterpool/strapi-client';
import { useRouter } from 'next/router';

import type { CarouselSlideAction, CarouselSlideGeneric } from '~/domains/cms/PromoCarousel/types';
import { CarouselSlideGenericAlign } from '~/domains/cms/PromoCarousel/enums';
import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import ContestCountdownPill from '~/domains/contest/domains/common/components/ContestCountdownPill/ContestCountdownPill';

import ActionButton from './ActionButton';
import ActionIcon from './ActionIcon';
import GenericSlideTitle from './GenericSlideTitle';
import useStyles from './GenericSlide.styles';
import backgroundImageExample from './backgroundImageExample.svg';

const parseTitle = (title: string) => {
  const lines = title.split(/\\n|\\\\n/g);
  return lines.map((line, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>
      {line}
      {index + 1 < lines.length ? <br /> : null}
    </React.Fragment>
  ));
};

function isReactElement(value: unknown): value is JSX.Element {
  return React.isValidElement(value);
}

type GenericSlideProps = Omit<
  CarouselSlideGeneric,
  'title' | 'subtitle' | '__component' | 'id' | 'trafficTypes' | 'actions' | 'onClickURL'
> & {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  actions?: (JSX.Element | CarouselSlideAction)[];
  isMobileVariant?: boolean;
  onClickURL?: string;
  contestStartDate?: Date;
};

function GenericSlide({
  title,
  subtitle,
  actions,
  backgroundImage = {
    name: 'background',
    mime: IMAGE_MIME_TYPES.SVG,
    url: backgroundImageExample.src,
    width: backgroundImageExample.width,
    height: backgroundImageExample.height,
  },
  onClickURL,
  rightImageDesktop,
  rightImageMobile,
  align = CarouselSlideGenericAlign.LEFT,
  isMobileVariant,
  contestStartDate,
}: GenericSlideProps) {
  const isMobile = useIsMobile() || isMobileVariant;
  const { classes, cx } = useStyles({
    backgroundImage: backgroundImage?.url,
    align,
    isMobile,
  });
  const rightImage = isMobile ? rightImageMobile : rightImageDesktop;
  const withImage = !!rightImage;
  const router = useRouter();
  const isContestCountDownVisible = Boolean(contestStartDate);

  // prettier-ignore
  // eslint-disable-next-line no-nested-ternary
  const stackSpacing = isContestCountDownVisible
    ? (isMobile ? 'xs' : 'xl')
    : (isMobile ? 'md' : 40);

  return (
    <MantineProvider inherit theme={{ colorScheme: 'dark', globalStyles: undefined }}>
      <Paper
        sx={{ cursor: 'pointer' }}
        className={classes.container}
        onClick={() => {
          if (onClickURL) {
            router.push(onClickURL);
          }
        }}
      >
        <Stack align={align} className={classes.content} spacing={stackSpacing}>
          {typeof title === 'string' ? (
            <GenericSlideTitle align={align} withImage={withImage} isMobile={isMobile}>
              {parseTitle(title)}
            </GenericSlideTitle>
          ) : (
            title
          )}
          {(() => {
            if (!subtitle) {
              return null;
            }
            if (typeof subtitle === 'string') {
              return <h4 className={cx(classes.subtitle, { withImage })}>{subtitle}</h4>;
            }

            return subtitle;
          })()}
          {actions?.length ? (
            <Group>
              {actions
                .filter((action) => {
                  if (isReactElement(action)) {
                    return true;
                  }
                  return !(action.hideOnMobile && isMobile);
                })
                .map((action) => {
                  if (isReactElement(action)) {
                    return action;
                  }

                  return (
                    <ActionButton key={action.id} variant={action.variant} href={action.href}>
                      {action.icon && (
                        <>
                          <ActionIcon icon={action.icon} />
                          &nbsp;
                        </>
                      )}
                      {action.label}
                    </ActionButton>
                  );
                })}
            </Group>
          ) : null}
          {isContestCountDownVisible ? (
            <ContestCountdownPill targetDate={contestStartDate} variant="light" />
          ) : null}
        </Stack>
        {rightImage ? (
          <Image
            src={rightImage.url}
            width={rightImage.width}
            height={rightImage.height}
            className={classes.rightImage}
            alt=""
          />
        ) : null}
      </Paper>
    </MantineProvider>
  );
}

export default GenericSlide;
